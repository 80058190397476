import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { ILoadFeatures } from '@wix/thunderbolt-features'
import {
	FeatureExportsSymbol,
	FeatureName,
	IFeatureStore,
	ExportsStoreSymbol,
	PartialFeatureStore,
	DeepPartial,
} from '@wix/thunderbolt-symbols'
import type { IFeatureExports } from './types'

const featureExports = (featureName: FeatureName) => (
	exportStore: IFeatureStore<PartialFeatureStore>
): IFeatureExports<DeepPartial<PartialFeatureStore>> => {
	return {
		export: (value: DeepPartial<PartialFeatureStore>) => {
			exportStore.update({ [featureName]: value })
		},
		get: (path: Array<string>): DeepPartial<PartialFeatureStore> => exportStore.get([featureName, ...path]),
	}
}

export const FeatureExports = (featureName: FeatureName) =>
	withDependencies([ExportsStoreSymbol], featureExports(featureName))

export const site = ({
	specificEnvFeaturesLoaders,
}: {
	specificEnvFeaturesLoaders: ILoadFeatures
}): ContainerModuleLoader => (bind) => {
	specificEnvFeaturesLoaders
		.getAllFeatureNames()
		.forEach((featureName) =>
			bind(FeatureExportsSymbol).to(FeatureExports(featureName)).whenTargetNamed(featureName)
		)
}

export { IFeatureExports }
